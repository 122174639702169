<template lang="pug">
v-form
  v-row
    v-col.mt-2( md="2" sm="6" cols="12" )
      MonthPicker(v-model="dateStart")
    v-col.mt-2( md="2" sm="6" cols="12" )
      v-select( v-model="project" item-text="name" item-value="id" :items="projects" label="Проект" )
    v-col( md="2" sm="6" cols="12" )
      v-autocomplete( v-model="network" item-text="name" item-value="id" label="Ритейлер" multiple chips :items="networks" )
    v-col( md="2" sm="6" cols="12" )
      v-autocomplete( v-model="city" label="Город" multiple chips :items="cities" )
    v-col( md="2" sm="6" cols="12" )
      v-autocomplete( v-model="user" :items="users" label="Пользователи" chips item-text="name" item-value="id" )
  v-row
    v-col
      v-btn( :disabled="!projectPicked" @click="loadReport" ) Получить отчёт
      v-btn.ml-2( :disabled="!projectPicked" @click="getExport" ) Выгрузить отчёт
      v-btn.ml-2( v-if="!isCustomer" :disabled="!projectPicked" @click="getZipFile" ) Выгрузить фотографии
  v-row
    v-col
      v-data-table.elevation-1.search-table( fixed-header loading-text="Загружается... Пожалуйста подождите" :loading="loading" :sort-by.sync="sortBy" :footer-props="footer_props" :height="tableHeight" :options.sync="options" :server-items-length="totalDesserts" :headers="headers" :items="reports" )
        template( v-slot:item="{ item }" )
          tr
            td( v-for="(header, key) in headers" :key="key" )
              //- v-btn(@click="loadMot(item)")
              //-   v-icon mdi-plus
              span( v-if="header.value == 'address'" v-html="item[header.value]" )
              span( v-else-if="header.value == 'tradePointExt'" v-html="item.extId" )
              div( v-else-if="item[header.value] != null" )
                router-link( :to="'motivationalReport/' + item.motivationalReportId + '/' + item[header.value].date" target='_blank' ) Мотивационный отчёт
                div Фото: {{ item[header.value].fotos }}

</template>
<script>
import DatePicker from './DatePicker';
import MonthPicker from './MonthPicker.vue';
import { projectService, networkService, tradepointService, motivationalReportService } from '@/_services'
import { mapMutations, mapState, mapGetters } from "vuex";

export default {

  components: {
    DatePicker,
    MonthPicker
  },

  data: () => ({
    dateStart: new Date().toISOString().substr(0, 7),
    project: 0,
    network: [],
    city: [],
    cities: [],
    user: 0,
    totalDesserts: 10,
    headers: [],
    reports: [],
    sortBy: 'address',
    options: {},
    footer_props: {
      'items-per-page-options': [25, 50, 100]
    },
    loading: false,
  }),

  mounted(){
    this.loadProjects()
  },

  computed: {
    ...mapState("project", ["projects"]),
    ...mapState("network", { allnetworks: "networks" }),
    ...mapGetters("user", ["users"]),
    ...mapGetters("authentication", ["checkRole"]),
    
    isCustomer() {
      return this.checkRole(0)
    },

    networks() {
      return this.allnetworks[this.project]
    },

    tableHeight() {
      return this.reports.length > 7 ? '500' : 'auto'
    },

    projectPicked(){
      return this.project
    }
  },

  watch: {
    project: function(val) {
      this.loadNetworks(val)
      this.loadCities()
    },

    network: function(val) {
      this.loadCities()
    },

    options: {
      handler() {
        this.loadReport();
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations("project", ["setProjects"]),
    ...mapMutations("network", ["setNetworkByProject"]),

    loadProjects() {
      if (this.projects.length > 0) return
      projectService.list().then(data => {
        this.setProjects(data)
        if (data.length > 0){
          this.project = data[0].id
        }
      })
    },

    loadNetworks(id) {
      if (this.networks && this.networks.length > 0) return
      networkService.loadMyNetwork(id).then(data => {
        this.setNetworkByProject({ id: id, networks: data })
      })
    },

    loadCities() {
      tradepointService.loadCities(this.project, this.network.join()).then(data => this.cities = data)
    },

    loadReport() {
      if (this.project === 0) return;
      this.loading = true
      let sort = this.options.sortBy.length > 0 ? this.options.sortBy[0] : '';
      let sortDesc = this.options.sortDesc.length > 0 ? this.options.sortDesc[0] : false;
      let params = { 
        start: new Date(new Date(Date.parse(this.dateStart)).getFullYear(), new Date(Date.parse(this.dateStart)).getMonth(), 2), 
        end: new Date(new Date(Date.parse(this.dateStart)).getFullYear(), new Date(Date.parse(this.dateStart)).getMonth() + 1, 1), 
        page: this.options.page - 1, 
        size: this.options.itemsPerPage, 
        sort, sortDesc };
      if (this.network.length > 0)
        params['networks'] = this.network.join()
      if (this.city.length > 0)
        params['cities'] = this.city.join()
      if (this.user > 0)
        params['user'] = this.user
      motivationalReportService.list(this.project, params).then(result => {
        this.totalDesserts = result.total
        this.headers = [{ text: 'ID', value: 'tradePointExt', fixed: true, width: '100px' }, { text: 'Адрес', value: 'address', fixed: true, width: '200px' }]
        for(let start = new Date(new Date(Date.parse(this.dateStart)).getFullYear(), new Date(Date.parse(this.dateStart)).getMonth(), 1), end = new Date(new Date(Date.parse(this.dateStart)).getFullYear(), new Date(Date.parse(this.dateStart)).getMonth() + 1, 0); start <= end; start.setDate(start.getDate() + 1)) {
          let field = this.dateFormat(start)
          this.headers.push({ text: field, value: field, sortable: false })
        }
        this.reports = []
        let data = result.data
        data.map(el => {
          let groupedData = el.reports.reduce((acc, item) => {
            item.date = new Date(Date.parse(item.date))
            let options = { year: 'numeric', month: '2-digit', day: '2-digit' };
            let dateWithoutTime = item.date.toLocaleDateString('en-CA', options);
            item.date = dateWithoutTime
            if (acc[item.date]) {
                acc[item.date].fotos += item.fotos;
            } else {
                acc[item.date] = { date: item.date, fotos: item.fotos};
            }
            return acc;
          },{});
          el.reports = Object.values(groupedData);
        })
        for(let i = 0;i < data.length;i++) {
          let obj = { id: data[i].tradePointId, extId: data[i].tradePointExt, address: `<b>${this.getNameNetwork(data[i].networkId)}</b>, ` + data[i].address }
          for(let j = 0;j < data[i].reports.length;j++) {
            let d = new Date(Date.parse(data[i].reports[j].date))
            let field = this.dateFormat(d)
            obj[field] = data[i].reports[j]
            obj.motivationalReportId = data[i].motivationalReportId
          }
          this.reports.push(obj)
        }
        this.loading = false
      })
    },

    dateFormat(date) {
      return `${date.getDate() < 10? '0' : ''}${date.getDate()}.${date.getMonth() + 1 < 10? '0' : ''}${date.getMonth() + 1}.${date.getFullYear()}`
    },

    getNameNetwork(id) {
      return this.networks.find(n => n.id === id).name
    },

    getExport() {
      this.loading = true
      let closer = () => {
        this.loading = false
        this.dialog = false
      }
      let createLink = (filename, url) => {
        let tempEl = document.createElement("a");
        document.body.appendChild(tempEl);
        tempEl.style = "display: none";
        tempEl.href = url;
        tempEl.download = filename;
        tempEl.click();
        tempEl.remove();
      };
    
      let params = { 
        start: new Date(new Date(Date.parse(this.dateStart)).getFullYear(), new Date(Date.parse(this.dateStart)).getMonth(), 2), 
        end: new Date(new Date(Date.parse(this.dateStart)).getFullYear(), new Date(Date.parse(this.dateStart)).getMonth() + 1, 1)
      };
      if (this.network.length > 0)
        params['networks'] = this.network.join()
      if (this.city.length > 0)
        params['cities'] = this.city.join()
      if (this.user > 0)
        params['user'] = this.user
      motivationalReportService.excel(this.project, params).then(function(response) {
        if ((typeof response.data) == 'string') {
          let content_disposition = response.headers['content-disposition'];
          let idx = content_disposition.indexOf('filename*=UTF-8\'\'');
          let filename = idx != -1 ? decodeURIComponent(content_disposition.substring(idx + 17)) : 'export';
          createLink(filename, response.data)
        } else {
          let content_disposition = response.headers['content-disposition'];
          let idx = content_disposition.indexOf('filename*=UTF-8\'\'');
          let filename = idx != -1 ? decodeURIComponent(content_disposition.substring(idx + 17)) : 'export';
          let blob = response.data;
          let objectURL = URL.createObjectURL(blob);
          createLink(filename, objectURL)
        }
        closer()
      }).catch((e) => {
        console.log(e)
        closer()
      })
    },

    getZipFile(){
      this.loading = true
      let closer = () => {
        this.loading = false
        this.dialog = false
      }
      let createLink = (filename, url) => {
        let tempEl = document.createElement("a");
        document.body.appendChild(tempEl);
        tempEl.style = "display: none";
        tempEl.href = url;
        tempEl.download = filename;
        tempEl.click();
        tempEl.remove();
      };
    
      let params = { 
        start: new Date(new Date(Date.parse(this.dateStart)).getFullYear(), new Date(Date.parse(this.dateStart)).getMonth(), 2), 
        end: new Date(new Date(Date.parse(this.dateStart)).getFullYear(), new Date(Date.parse(this.dateStart)).getMonth() + 1, 1)
      };
      if (this.network.length > 0)
        params['networks'] = this.network.join()
      if (this.city.length > 0)
        params['cities'] = this.city.join()
      if (this.user > 0)
        params['user'] = this.user
      let projectName = this.projects.find(pr => pr.id == this.project).name
      motivationalReportService.zip(this.project, params).then(function(response) {
        let filename = projectName + "_" +"мотивационный отчёт"
        let blob = response.data;
        let objectURL = URL.createObjectURL(blob);
        createLink(filename, objectURL)
        closer()
      }).catch((e) => {
        console.log(e)
        closer()
      })
    }
  }
}
</script>

<style>
    .search-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(1),
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(1),
    .search-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2),
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0;
        background: white;
        border-right: 1px solid #000;
        font-size: 13px;
        z-index: 3;
    }
    .search-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2),
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2) {
        left: 100px;
    }
    .search-table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(2n + 3),
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2n + 3) {
        background: #f9f9f9
    }
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(1),
    .search-table > .v-data-table__wrapper > table > thead > tr > th:nth-child(2) {
        z-index: 4;
    }
    .search-table > .v-data-table__wrapper > table > tbody > tr:hover > td {
        background: #ececec
    }
    a:visited {
        color: #609;
    }
</style>